import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  CatalogCategoryType,
  CatalogExtendedRelationType,
  ProductType,
  CategoryRelationType,
} from '@/typings/model';
import {
  getCatalogCategoryByAlias,
  getCatalogExtendedRelationById,
  getCategoryProductsById,
  getCategoryRelationById,
} from '@/services/requests';

const categoriesLoader = createResourceLoader<CatalogCategoryType>(null);
const catalogExtendedRelationListLoader = createResourceLoader<
  Array<CatalogExtendedRelationType>
>([]);
const categoryRelationListLoader = createResourceLoader<
  Array<CategoryRelationType>
>([]);
const productListLoader = createResourceLoader<Array<ProductType>>([]);

type StateType = {
  categories: Record<string, ResourceType<CatalogCategoryType>>;
  catalogExtendedRelationList: Record<
    number,
    ResourceType<Array<CatalogExtendedRelationType>>
  >;
  categoryRelationList: Record<
    number,
    ResourceType<Array<CategoryRelationType>>
  >;
  categoryProductList: Record<number, ResourceType<Array<ProductType>>>;
};

const initialState: StateType = {
  categories: {},
  catalogExtendedRelationList: [],
  categoryRelationList: [],
  categoryProductList: [],
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {
    /** Categories */
    categoriesRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.categories[action.payload.key] = categoriesLoader.pending();
    },
    categoriesRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, CatalogCategoryType>>
    ) {
      state.categories[action.payload.key] = categoriesLoader.fulfill(
        action.payload.value
      );
    },
    categoriesRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.categories[action.payload.key] = categoriesLoader.reject();
    },

    /** Category Extended Relation List */
    catalogExtendedRelationListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.catalogExtendedRelationList[
        action.payload.key
      ] = catalogExtendedRelationListLoader.pending();
    },
    catalogExtendedRelationListRequestFulfilled(
      state,
      action: PayloadAction<
        MapEntry<number, Array<CatalogExtendedRelationType>>
      >
    ) {
      state.catalogExtendedRelationList[
        action.payload.key
      ] = catalogExtendedRelationListLoader.fulfill(action.payload.value);
    },
    catalogExtendedRelationListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.catalogExtendedRelationList[
        action.payload.key
      ] = catalogExtendedRelationListLoader.reject();
    },

    /** Category Relation List */
    categoryRelationListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryRelationList[
        action.payload.key
      ] = categoryRelationListLoader.pending();
    },
    categoryRelationListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<CategoryRelationType>>>
    ) {
      state.categoryRelationList[
        action.payload.key
      ] = categoryRelationListLoader.fulfill(action.payload.value);
    },
    categoryRelationListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryRelationList[
        action.payload.key
      ] = categoryRelationListLoader.reject();
    },

    /** Category Product List */
    categoryProductListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryProductList[
        action.payload.key
      ] = productListLoader.pending();
    },
    categoryProductListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.categoryProductList[action.payload.key] = productListLoader.fulfill(
        action.payload.value
      );
    },
    categoryProductListLoadedMore(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      const productListResource = state.categoryProductList[action.payload.key];
      productListResource.data = [
        ...productListResource.data,
        ...action.payload.value,
      ];
    },
    categoryProductListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryProductList[
        action.payload.key
      ] = productListLoader.reject();
    },
  },
});

const { actions, reducer } = catalogSlice;
export const {
  categoriesRequestPending,
  categoriesRequestFulfilled,
  categoriesRequestRejected,
  catalogExtendedRelationListRequestPending,
  catalogExtendedRelationListRequestFulfilled,
  catalogExtendedRelationListRequestRejected,
  categoryRelationListRequestPending,
  categoryRelationListRequestFulfilled,
  categoryRelationListRequestRejected,
  categoryProductListRequestPending,
  categoryProductListRequestFulfilled,
  categoryProductListRequestRejected,
  categoryProductListLoadedMore,
} = actions;

export function getCatalogCategoryByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<CatalogCategoryType>> {
  return async (dispatch, getState) => {
    try {
      const category = selectCatalogCategoryByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && category) {
        return category.data;
      }

      dispatch(categoriesRequestPending({ key: alias }));
      const response = await getCatalogCategoryByAlias({ path: alias });

      dispatch(
        categoriesRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoriesRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getCatalogExtendedRelationByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<CatalogExtendedRelationType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectCatalogExtendedRelationByIdResource(
        getState(),
        id
      );

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(catalogExtendedRelationListRequestPending({ key: id }));
      const response = await getCatalogExtendedRelationById(id);

      dispatch(
        catalogExtendedRelationListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(catalogExtendedRelationListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function getCategoryRelationByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<CategoryRelationType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectCategoryRelationByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(categoryRelationListRequestPending({ key: id }));
      const response = await getCategoryRelationById(id);

      dispatch(
        categoryRelationListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryRelationListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function getCategoryProductsByIdThunk(
  id: number,
  params: URLSearchParams,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectCategoryProductsByIdResource(getState(), id);
      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(categoryProductListRequestPending({ key: id }));
      const response = await getCategoryProductsById(id, params);

      dispatch(
        categoryProductListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryProductListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectCatalogCategoryByAliasResource(
  state: AppState,
  alias: string
): ResourceType<CatalogCategoryType> {
  return state.pages.catalog.categories[alias];
}

export function selectCatalogExtendedRelationByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<CatalogExtendedRelationType>> {
  return state.pages.catalog.catalogExtendedRelationList[id];
}

export function selectCategoryRelationByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<CategoryRelationType>> {
  return state.pages.catalog.categoryRelationList[id];
}

export function selectCategoryProductsByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<ProductType>> {
  return state.pages.catalog.categoryProductList[id];
}

export function selectCatalogCategoryByAlias(
  state: AppState,
  alias: string
): CatalogCategoryType {
  return selectCatalogCategoryByAliasResource(state, alias).data;
}

export default reducer;
