import {
  convertParamsToString,
  getSearchParamsFromUrl,
  parseBooleanParam,
} from '@tager/web-core';

import {
  BaseFilterConfigType,
  BaseFilterType,
  BooleanFilterConfigType,
  BooleanFilterType,
  FiltersType,
  MultiSelectFilterConfigType,
  MultiSelectFilterType,
  RangeFilterConfigType,
  RangeFilterType,
} from '@/typings/model';
import { BASE_FILTERS_TYPE } from '@/constants/filters';

interface BaseFieldService<
  Config extends BaseFilterConfigType = BaseFilterConfigType,
  Field extends BaseFilterType = BaseFilterType,
  Value = unknown
> {
  config: Readonly<Config>;

  getDefaultValue(config?: Config): Value;

  getValueAsSearchParam(value: Value): string;

  getValueFromUrl(): Value | null;

  getCurrentValueFromUrl(): Value;

  getInitialField(value?: Value): Field;

  getInitialFieldWithDefaultValues(): Field;
}

export type MultiSelectFieldValue = Array<string> | [];

class MultiSelectField
  implements
    BaseFieldService<
      MultiSelectFilterConfigType,
      MultiSelectFilterType,
      MultiSelectFieldValue
    > {
  config: Readonly<MultiSelectFilterConfigType>;

  constructor(config: Readonly<MultiSelectFilterConfigType>) {
    this.config = config;
  }

  getDefaultValue(): MultiSelectFieldValue {
    const options = this.config.options;
    let defaultValues: MultiSelectFieldValue = [];
    options.forEach((option) => {
      if (option.checked) {
        defaultValues = [...defaultValues, option.id];
      }
    });
    return defaultValues.length !== 0 ? defaultValues : [];
  }

  getValueAsSearchParam(value: MultiSelectFieldValue): string {
    if (value.length !== 0) {
      const searchItems: string[] = [];
      const key = this.config.id;
      value.forEach((searchValue) => {
        searchItems.push(searchValue);
      });
      const searchParams =
        searchItems.length > 1 ? searchItems.join(',') : searchItems.join('');
      return `${key}=${searchParams}`;
    }
    return '';
  }

  getValueFromUrl(): string[] | null {
    const searchParams = getSearchParamsFromUrl(document.location.href);
    const key = searchParams.get(this.config.id);
    if (!key) return null;
    return key.split(',');
  }

  getCurrentValueFromUrl(): MultiSelectFieldValue {
    const value = this.getValueFromUrl();
    return value ? value : this.getDefaultValue();
  }

  getInitialField(value?: MultiSelectFieldValue): MultiSelectFilterType {
    const initialValue =
      value !== undefined
        ? value
        : this.getValueFromUrl() ?? this.getDefaultValue();
    return { ...this.config, value: initialValue };
  }

  getInitialFieldWithDefaultValues(): MultiSelectFilterType {
    const value = this.getDefaultValue();
    return { ...this.config, value: value };
  }
}

export type BooleanFieldValue = boolean | null;

class BooleanField
  implements
    BaseFieldService<
      BooleanFilterConfigType,
      BooleanFilterType,
      BooleanFieldValue
    > {
  config: Readonly<BooleanFilterConfigType>;

  constructor(config: Readonly<BooleanFilterConfigType>) {
    this.config = config;
  }

  getDefaultValue(): BooleanFieldValue {
    return null;
  }

  getValueAsSearchParam(value: BooleanFieldValue): string {
    const searchParam =
      value === true || value === false ? value.toString() : '';
    const key = this.config.id;
    return !!searchParam ? `${key}=${searchParam}` : '';
  }

  getValueFromUrl(): null | boolean {
    const searchParams = getSearchParamsFromUrl(document.location.href);
    const param = searchParams.get(this.config.id);
    return parseBooleanParam(param);
  }

  getCurrentValueFromUrl(): BooleanFieldValue {
    return this.getValueFromUrl();
  }

  getInitialField(value?: boolean | null): BooleanFilterType {
    const initialValue =
      value !== undefined
        ? value
        : this.getValueFromUrl() ?? this.getDefaultValue();
    return { ...this.config, value: initialValue };
  }

  getInitialFieldWithDefaultValues(): BooleanFilterType {
    const value = this.getDefaultValue();
    return { ...this.config, value: value };
  }
}

export type RangeFieldValue = [number, number];

class RangeField
  implements
    BaseFieldService<RangeFilterConfigType, RangeFilterType, RangeFieldValue> {
  config: Readonly<RangeFilterConfigType>;

  constructor(config: Readonly<RangeFilterConfigType>) {
    this.config = config;
  }

  getDefaultValue(): RangeFieldValue {
    return [this.config.rangeFrom, this.config.rangeTo];
  }

  getValueAsSearchParam(value: RangeFieldValue) {
    if (value.length !== 2) return '';
    const searchParams = value.join(':');
    const key = this.config.id;
    return `${key}=${searchParams}`;
  }

  getValueFromUrl(): RangeFieldValue | null {
    const searchParams = getSearchParamsFromUrl(document.location.href);
    const param = searchParams.get(this.config.id);
    if (!param) return null;
    const range = param.split(':').map((item) => Number(item));
    return [range[0], range[1]];
  }

  getCurrentValueFromUrl(): RangeFieldValue {
    const value = this.getValueFromUrl();
    return value ? value : [this.config.rangeFrom, this.config.rangeTo];
  }

  getInitialField(value?: RangeFieldValue): RangeFilterType {
    const initialValue =
      value !== undefined
        ? value
        : this.getValueFromUrl() ?? this.getDefaultValue();
    return { ...this.config, value: initialValue };
  }

  getInitialFieldWithDefaultValues(): RangeFilterType {
    const value = this.getDefaultValue();
    return { ...this.config, value: value };
  }
}

export type FieldConfigUnion =
  | RangeFilterConfigType
  | MultiSelectFilterConfigType
  | BooleanFilterConfigType;
export type FieldServiceUnion = RangeField | MultiSelectField | BooleanField;
const FILTERS_TYPE = {
  MULTISELECT: 'MULTISELECT',
  RANGE: 'RANGE',
  BOOLEAN: 'TRUE_FALSE',
} as const;

export function getFieldService(
  fieldConfig: FieldConfigUnion
): FieldServiceUnion {
  let fieldService: FieldServiceUnion;
  switch (fieldConfig.type) {
    case FILTERS_TYPE.MULTISELECT:
      fieldService = new MultiSelectField(
        fieldConfig as MultiSelectFilterConfigType
      );
      break;
    case FILTERS_TYPE.RANGE:
      fieldService = new RangeField(fieldConfig as RangeFilterConfigType);
      break;
    case FILTERS_TYPE.BOOLEAN:
      fieldService = new BooleanField(fieldConfig as BooleanFilterConfigType);
      break;
  }
  return fieldService;
}

export function getInitialFilters(filterList: Array<BaseFilterConfigType>) {
  return filterList.map((fieldConfig) => {
    const fieldService = getFieldService(fieldConfig as FieldConfigUnion);
    return fieldService.getInitialField();
  });
}

export function getInitialFiltersWithDefaultValues(
  filterList: Array<BaseFilterConfigType>
) {
  return filterList.map((fieldConfig) => {
    const fieldService = getFieldService(fieldConfig as FieldConfigUnion);
    return fieldService.getInitialFieldWithDefaultValues();
  });
}

export function getDefaultSearchParamsToUrl(filterList: Array<BaseFilterType>) {
  return filterList.map((fieldConfig) => {
    const fieldService = getFieldService(fieldConfig as FieldConfigUnion);
    return fieldService.getValueAsSearchParam(fieldConfig.value);
  });
}

export function getNewFiltersWhenChangeQueryParams(
  filters: Array<BaseFilterType>
): Array<BaseFilterType> {
  const initialFiltersValue: { [key: string]: any } = {};
  filters.forEach((filter) => {
    const fieldService = getFieldService(filter as FieldConfigUnion);
    initialFiltersValue[filter.id] = fieldService.getCurrentValueFromUrl();
  });
  return filters.map((filter) => {
    return {
      ...filter,
      value: initialFiltersValue[filter.id],
    };
  }) as Array<BaseFilterType>;
}

export function getBaseSearchParamsToUrl(
  initialFilters: BaseFilterType[]
): string {
  let defaultSearchParams = getDefaultSearchParamsToUrl(initialFilters)
    .filter(Boolean)
    .join('&');
  const localObject: { [key: string]: string } = {};
  const currentSearchParamsInUrl = getSearchParamsFromUrl(
    document.location.href
  );
  const currentSearchParamsDefault = new URLSearchParams(defaultSearchParams);
  currentSearchParamsDefault.forEach((value, key) => {
    localObject[key] = value;
  });
  currentSearchParamsInUrl.forEach((value, key) => {
    localObject[key] = value;
  });
  return convertParamsToString(localObject);
}

export function getQueryStringFromSelectedFilters(
  filters: Array<BaseFilterType>
): string {
  const checkFilters = checkRangeFilterOnDefaultValue(filters);
  let queryString = checkFilters
    .map((filter) => {
      const fieldService = getFieldService(filter as FieldConfigUnion);
      return fieldService.getValueAsSearchParam(filter.value);
    })
    .filter(Boolean)
    .join('&');
  const currentSearchParams = new URLSearchParams(document.location.search);
  const sortParam = currentSearchParams.get('sort');
  if (sortParam) {
    queryString += `&sort=${sortParam}`;
  }
  return new URLSearchParams(queryString).toString();
}

type BaseTagType = {
  label: string;
  value: string;
  id: string;
  parentId: string;
  type: FiltersType;
};

export function getCurrentFilersInTagsFormat(
  filters: Array<BaseFilterType>
): BaseTagType[] {
  const tags: BaseTagType[] = [];
  const currentSearchParams = getSearchParamsFromUrl(document.location.search);
  currentSearchParams.delete('sort');
  currentSearchParams.forEach((value, key) => {
    const targetObject = filters.find((item) => item.id === key);
    if (targetObject?.type === 'RANGE') {
      tags.push({
        parentId: key,
        id: key,
        label: targetObject.label,
        value: value,
        type: targetObject.type,
      });
    }
    if (targetObject?.type === 'MULTISELECT') {
      const parentId = key;
      const values = value.split(',');
      const parentFilter = filters.find((parent) => parent.id === parentId);
      values.forEach((id) => {
        const targetOption = parentFilter?.options.find(
          (option) => option.id === id
        );
        tags.push({
          parentId: key,
          id: id,
          label: parentFilter?.label ?? '',
          value: targetOption?.label ?? '',
          type: 'MULTISELECT',
        });
      });
    }
    if (targetObject?.type === 'TRUE_FALSE') {
      const targetFilter = filters.find((filter) => filter.id === key);
      tags.push({
        parentId: key,
        id: key,
        label: targetFilter?.label ?? '',
        value: targetFilter?.label ?? '',
        type: 'TRUE_FALSE',
      });
    }
  });
  return tags;
}

function checkRangeFilterOnDefaultValue(
  filters: Array<BaseFilterType>
): Array<BaseFilterType> {
  return filters.map((filter) => {
    if (filter.type === BASE_FILTERS_TYPE.RANGE) {
      const baseValues = [filter.rangeFrom, filter.rangeTo];
      const currentValues = [filter.value[0], filter.value[1]];
      if (
        currentValues[0] === baseValues[0] &&
        currentValues[1] === baseValues[1]
      ) {
        return { ...filter, value: [] };
      }
    }
    return filter;
  });
}
