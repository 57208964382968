import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';
import { getMenuItemListByAlias, MenuItemType } from '@tager/web-modules';

import { AppState, AppThunk } from '@/store/store';
import { CatalogMenuType, ProductType } from '@/typings/model';
import {
  getCatalogMenu,
  getMenuCategoryProductsById,
  getMenuOfferProductsById,
} from '@/services/requests';

const catalogMenuLoader = createResourceLoader<Array<CatalogMenuType>>([]);
const categoryProductListLoader = createResourceLoader<Array<ProductType>>([]);
const offerProductListLoader = createResourceLoader<Array<ProductType>>([]);

type State = {
  menuMap: Record<string, Array<MenuItemType>>;
  catalogMenu: ResourceType<Array<CatalogMenuType>>;
  categoryProductList: Record<number, ResourceType<Array<ProductType>>>;
  offerProductList: Record<number, ResourceType<Array<ProductType>>>;
};

const initialState: State = {
  menuMap: {},
  catalogMenu: catalogMenuLoader.getInitialResource(),
  categoryProductList: [],
  offerProductList: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    /** Catalog Menu */
    catalogMenuRequestPending(state) {
      state.catalogMenu = catalogMenuLoader.pending();
    },
    catalogMenuRequestFulfilled(
      state,
      action: PayloadAction<Array<CatalogMenuType>>
    ) {
      state.catalogMenu = catalogMenuLoader.fulfill(action.payload);
    },
    catalogMenuRequestRejected(state) {
      state.catalogMenu = catalogMenuLoader.reject();
    },

    /** Category Products */
    categoryProductListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryProductList[
        action.payload.key
      ] = categoryProductListLoader.pending();
    },
    categoryProductListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.categoryProductList[
        action.payload.key
      ] = categoryProductListLoader.fulfill(action.payload.value);
    },
    categoryProductListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.categoryProductList[
        action.payload.key
      ] = categoryProductListLoader.reject();
    },

    /** Offer Products */
    offerProductListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.pending();
    },
    offerProductListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.fulfill(action.payload.value);
    },
    offerProductListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.reject();
    },

    /** Menu List */
    menuItemListAdded(
      state,
      action: PayloadAction<MapEntry<string, Array<MenuItemType>>>
    ) {
      state.menuMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = menuSlice;
export const {
  catalogMenuRequestPending,
  catalogMenuRequestFulfilled,
  catalogMenuRequestRejected,
  categoryProductListRequestPending,
  categoryProductListRequestFulfilled,
  categoryProductListRequestRejected,
  offerProductListRequestPending,
  offerProductListRequestFulfilled,
  offerProductListRequestRejected,
  menuItemListAdded,
} = actions;

export default reducer;

export function getMenuItemListThunk(
  menuAlias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<Array<MenuItemType>>>> {
  return async (dispatch, getState) => {
    try {
      const menuItemList = selectMenuItemListByAlias(getState(), menuAlias);

      if (!options?.shouldInvalidate && menuItemList) {
        return menuItemList;
      }

      const response = await getMenuItemListByAlias(menuAlias);

      dispatch(
        menuItemListAdded({
          key: menuAlias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function getCatalogMenuThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<CatalogMenuType>>> {
  return async (dispatch, getState) => {
    const catalogMenuResource = selectCatalogMenuResource(getState());
    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      catalogMenuResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return catalogMenuResource.data;
    }

    dispatch(catalogMenuRequestPending());

    try {
      const response = await getCatalogMenu();
      dispatch(catalogMenuRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      dispatch(catalogMenuRequestRejected());
      return [];
    }
  };
}

export function getMenuCategoryProductsByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectMenuCategoryProductsByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(categoryProductListRequestPending({ key: id }));
      const response = await getMenuCategoryProductsById(id);

      dispatch(
        categoryProductListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(categoryProductListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function getMenuOfferProductsByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectMenuOfferProductsByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(offerProductListRequestPending({ key: id }));
      const response = await getMenuOfferProductsById(id);

      dispatch(
        offerProductListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(offerProductListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectMenuItemListByAlias(
  state: AppState,
  menuAlias: string
): Array<MenuItemType> | undefined {
  return state.tager.menus.menuMap[menuAlias];
}

export function selectCatalogMenuResource(
  state: AppState
): ResourceType<Array<CatalogMenuType>> {
  return state.tager.menus.catalogMenu;
}

export function selectMenuCategoryProductsByIdResource(
  state: AppState,
  id: null | number
): ResourceType<Array<ProductType>> | null {
  if (!id) return null;
  return state.tager.menus.categoryProductList[id];
}

export function selectMenuOfferProductsByIdResource(
  state: AppState,
  id: null | number
): ResourceType<Array<ProductType>> | null {
  if (!id) return null;
  return state.tager.menus.offerProductList[id];
}
