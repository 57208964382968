import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { CollectionType, ProductType } from '@/typings/model';
import {
  getCollectionByAlias,
  getCollectionProductsById,
} from '@/services/requests';

const collectionsLoader = createResourceLoader<CollectionType>(null);
const collectionProductsListLoader = createResourceLoader<Array<ProductType>>(
  []
);

type StateType = {
  collections: Record<string, ResourceType<CollectionType>>;
  collectionProductsList: Record<number, ResourceType<Array<ProductType>>>;
};

const initialState: StateType = {
  collections: {},
  collectionProductsList: [],
};

const collectionSlice = createSlice({
  name: 'collection',
  initialState: initialState,
  reducers: {
    /** Collections */
    collectionsRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.collections[action.payload.key] = collectionsLoader.pending();
    },
    collectionsRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, CollectionType>>
    ) {
      state.collections[action.payload.key] = collectionsLoader.fulfill(
        action.payload.value
      );
    },
    collectionsRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.collections[action.payload.key] = collectionsLoader.reject();
    },

    /** Collection Products */
    collectionProductsListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.collectionProductsList[
        action.payload.key
      ] = collectionProductsListLoader.pending();
    },
    collectionProductsListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.collectionProductsList[
        action.payload.key
      ] = collectionProductsListLoader.fulfill(action.payload.value);
    },
    collectionProductsListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.collectionProductsList[
        action.payload.key
      ] = collectionProductsListLoader.reject();
    },
  },
});

const { actions, reducer } = collectionSlice;
export const {
  collectionsRequestPending,
  collectionsRequestFulfilled,
  collectionsRequestRejected,
  collectionProductsListRequestPending,
  collectionProductsListRequestFulfilled,
  collectionProductsListRequestRejected,
} = actions;

export function getCollectionByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<CollectionType>> {
  return async (dispatch, getState) => {
    try {
      const collection = selectCollectionByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && collection) {
        return collection.data;
      }
      dispatch(collectionsRequestPending({ key: alias }));
      const response = await getCollectionByAlias(alias);
      dispatch(
        collectionsRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(collectionsRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getCollectionProductsByIdThunk(
  id: number,
  searchParams: URLSearchParams,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectCollectionProductsByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(collectionProductsListRequestPending({ key: id }));
      const response = await getCollectionProductsById(id, searchParams);

      dispatch(
        collectionProductsListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(collectionProductsListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectCollectionByAliasResource(
  state: AppState,
  alias: string
): ResourceType<CollectionType> {
  return state.pages.collection.collections[alias];
}

export function selectCollectionProductsByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<ProductType>> {
  return state.pages.collection.collectionProductsList[id];
}

export default reducer;
