import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import blogReducer from './tager/blog';
import productsReducer from './pages/products';
import productReducer from './pages/product';
import catalogReducer from './pages/catalog';
import searchReducer from './pages/search';
import collectionReducer from './pages/collection';
import brandReducer from './pages/brand';
import offerReducer from './pages/offer';
import filterReducer from './filter';

const tagerReducer = combineReducers({
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
  blog: blogReducer,
});

const pageDataReducer = combineReducers({
  products: productsReducer,
  product: productReducer,
  catalog: catalogReducer,
  search: searchReducer,
  collection: collectionReducer,
  brand: brandReducer,
  offer: offerReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  pages: pageDataReducer,
  filter: filterReducer,
});

export default rootReducer;
