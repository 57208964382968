import React from 'react';

import { getImageTypeFromUrl, Nullable, RequestError } from '@tager/web-core';
import { SeoParamsType } from '@tager/web-modules';
import { ThumbnailType } from '@tager/web-modules/src/typings/models';

import ErrorPage from '@/pages/_error';
import { StockVariant, StringFieldType } from '@/typings/common';
import {
  BaseFilterConfigType,
  BaseFilterType,
  BooleanFilterConfigType,
  BooleanFilterType,
  MultiSelectFilterConfigType,
  MultiSelectFilterType,
  RangeFilterConfigType,
  RangeFilterType,
} from '@/typings/model';

export function getCurrentProject(): string | undefined {
  return process.env.NEXT_PUBLIC_PROJECT;
}

export function getThemeColor(): string | undefined {
  return process.env.NEXT_PUBLIC_THEME_COLOR;
}

export function getApplicationName(): string | undefined {
  return process.env.NEXT_PUBLIC_APPLICATION_NAME;
}

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';
  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

export function convertAliasToPath(
  alias: Array<string> | string | undefined
): string {
  if (!alias) return '';
  const convertString = convertSlugToPath(alias);

  return convertString.substring(convertString.indexOf('/') + 1);
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function normalizePhoneNumber(phone: string | null): string | null {
  if (!phone) return null;
  return phone.replace(/[^+\d]/g, '');
}

export function normalizePrice(price: number): string | number {
  if (!price) {
    return 0;
  }
  let parsedPrice: string;
  if (Number.isInteger(price)) {
    parsedPrice = price.toString();
  } else {
    parsedPrice = price.toFixed(2);
  }
  return parsedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const FINAL_NUMBER_REGEXP = /^[+-]?(?:\d*\.?\d+|\d+\.?\d*)(?:[Ee][+-]?\d{1,2})?$/;
export const INTERMEDIATE_NUMBER_REGEXP = /^[+-]?(?:\d*(?:\.?(?:\d+(?:(?:(?:[Ee]\+)|(?:[Ee]-)|(?:[Ee]))?(?:\d{1,2})?)?)?)?)?$/;
export const INTERMEDIATE_INTEGER_REGEXP = /^[+-]?(?:\d+)?$/;
export const DOT_REGEXP = /[,/\u0431\u044E]/;

export function floatParser(
  currentValue: string,
  previousValue: string
): string {
  /**
   * u0435 = small cyrillic "е"
   * u0443 = small cyrillic "у"
   *
   * We replace cyrillic "у", because on cyrillic keyboard
   * this character and latin "e" are located on the same button
   * We replace cyrillic "e", because this character looks exactly like latin "e"
   * */
  const parsedString = currentValue
    .replace(DOT_REGEXP, '.')
    .replace(/[\u0435\u0443]/, 'e');
  return INTERMEDIATE_NUMBER_REGEXP.test(parsedString)
    ? parsedString
    : previousValue;
}

export function integerParser(
  currentValue: string,
  previousValue: string
): string {
  return INTERMEDIATE_INTEGER_REGEXP.test(currentValue)
    ? currentValue
    : previousValue;
}

export function getProductsOrPostsOrCategoriesWord(
  count: number,
  word: 'Products' | 'Posts' | 'Categories'
): string {
  if (count % 10 === 1 && count !== 11) {
    return word === 'Products'
      ? 'товар'
      : word === 'Posts'
      ? 'пост'
      : 'категория';
  }

  if (count % 10 >= 2 && count % 10 <= 4 && (count < 12 || count > 14)) {
    return word === 'Products'
      ? 'товара'
      : word === 'Posts'
      ? 'поста'
      : 'категории';
  }

  return word === 'Products'
    ? 'товаров'
    : word === 'Posts'
    ? 'постов'
    : 'категорий';
}

export function getStockLabel(stock: StockVariant | null) {
  switch (stock) {
    case 'WAITING':
      return 'Под заказ';
    case 'STOCK':
      return 'На складе';
    case 'IN_SHOP':
    case 'SHOP':
      return 'В магазине';
    default:
      return 'Нет в наличии';
  }
}

export function changeShadeColor(color: StringFieldType, percent: number) {
  if (!color) return null;

  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.round((R * (100 + percent)) / 100);
  G = Math.round((G * (100 + percent)) / 100);
  B = Math.round((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

export function getCookie(name: string) {
  let matches = global.document?.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function isSvg(imageUrl: string): boolean {
  return getImageTypeFromUrl(imageUrl) === 'image/svg+xml';
}

export function normalizeThumbnail(
  commonImage: Nullable<ThumbnailType>,
  retinaImage: Nullable<ThumbnailType>
): ThumbnailType {
  if (commonImage?.url && isSvg(commonImage.url)) {
    return { url: commonImage.url };
  }

  if (retinaImage?.url && isSvg(retinaImage.url)) {
    return { url: retinaImage.url };
  }

  return {
    url: commonImage?.url ?? null,
    url_webp: commonImage?.url_webp,
    url_2x: retinaImage?.url,
    url_webp_2x: retinaImage?.url_webp,
  };
}

export function getDefaultSearchParams(
  searchParams: URLSearchParams,
  defaultSearchParams: { [key: string]: string }
) {
  const fullSearchParams = new URLSearchParams();
  Object.keys(defaultSearchParams).forEach((searchKey) => {
    const param = searchParams.get(searchKey);
    if (param) {
      fullSearchParams.set(searchKey, param);
    } else {
      fullSearchParams.set(searchKey, defaultSearchParams[searchKey]);
    }
  });
  return fullSearchParams;
}

/** MULTISELECT */
export function isMultiSelectFilterConfig(
  filter: BaseFilterConfigType
): filter is MultiSelectFilterConfigType {
  return filter.type === 'MULTISELECT';
}

export function isMultiSelectFilter(
  filter: BaseFilterType
): filter is MultiSelectFilterType {
  return isMultiSelectFilterConfig(filter);
}

/** RANGE */
export function isRangeFilterConfig(
  filter: BaseFilterConfigType
): filter is RangeFilterConfigType {
  console.log('current FILTER', filter);
  return filter.type === 'RANGE';
}

export function isRangeFilter(
  filter: BaseFilterType
): filter is RangeFilterType {
  return isRangeFilterConfig(filter);
}

/** TRUE_FALSE */
export function isBooleanFilterConfig(
  filter: BaseFilterConfigType
): filter is BooleanFilterConfigType {
  return filter.type === 'TRUE_FALSE';
}

export function isBooleanFilter(
  filter: BaseFilterType
): filter is BooleanFilterType {
  return isBooleanFilterConfig(filter);
}

export function updateMultiSelectFilterValue(
  filterConfig: MultiSelectFilterConfigType,
  value: MultiSelectFilterType['value']
): MultiSelectFilterType {
  return { ...filterConfig, value };
}
