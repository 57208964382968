import React, { useState } from 'react';

export interface NavigationMenuContextType {
  openNavigation: () => void;
  closeNavigation: () => void;
  toggleNavigation: () => void;
  setNavigationMounted: (value: boolean) => void;
  setNavigationVisible: (value: boolean) => void;
  isNavigationMounted: boolean;
  isNavigationVisible: boolean;
}

interface Props {
  children: React.ReactNode;
}

export const NavigationMenuContext = React.createContext<NavigationMenuContextType | null>(
  null
);

function NavigationMenuProvider({ children }: Props) {
  const [isNavigationVisible, setNavigationVisible] = useState<boolean>(false);
  const [isNavigationMounted, setNavigationMounted] = useState<boolean>(false);

  const openNavigation = () => setNavigationMounted(true);
  const closeNavigation = () => setNavigationVisible(false);
  const toggleNavigation = () =>
    isNavigationMounted ? closeNavigation() : openNavigation();

  const values = {
    openNavigation,
    closeNavigation,
    toggleNavigation,
    setNavigationVisible,
    setNavigationMounted,
    isNavigationVisible,
    isNavigationMounted,
  } as NavigationMenuContextType;

  return (
    <NavigationMenuContext.Provider value={values}>
      {children}
    </NavigationMenuContext.Provider>
  );
}

export default NavigationMenuProvider;
