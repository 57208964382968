import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { BaseFilterType } from '@/typings/model';
import { getProductsFiltersByCategoryId } from '@/services/requests';
import { getInitialFilters } from '@/services/filters';

const filtersLoader = createResourceLoader<Array<BaseFilterType>>([]);

type StateType = {
  isFilterOpened: boolean;
  isFilterWasApplied: boolean;
  filters: Record<string, ResourceType<Array<BaseFilterType>>>;
};

const initialState: StateType = {
  isFilterOpened: false,
  isFilterWasApplied: false,
  filters: {},
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    changeFilterValue(state, action: PayloadAction<boolean>) {
      state.isFilterOpened = action.payload;
    },
    changeFilterWasAppliedValue(state, action: PayloadAction<boolean>) {
      state.isFilterWasApplied = action.payload;
    },
    filtersRequestPending(state, action: PayloadAction<{ key: number }>) {
      state.filters[action.payload.key] = filtersLoader.pending();
    },
    filtersRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<BaseFilterType>>>
    ) {
      state.filters[action.payload.key] = filtersLoader.fulfill(
        action.payload.value
      );
    },
    filtersRequestRejected(state, action: PayloadAction<{ key: number }>) {
      state.filters[action.payload.key] = filtersLoader.reject();
    },
  },
});

const { actions, reducer } = filterSlice;

export const {
  changeFilterValue,
  filtersRequestFulfilled,
  changeFilterWasAppliedValue,
  filtersRequestPending,
  filtersRequestRejected,
} = actions;

export function getProductsFiltersByIdThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<BaseFilterType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectFilterByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }
      dispatch(filtersRequestPending({ key: id }));
      const response = await getProductsFiltersByCategoryId(Number(id));

      const initFilters = getInitialFilters(response.data);

      dispatch(filtersRequestFulfilled({ key: id, value: initFilters }));

      return initFilters;
    } catch (error) {
      dispatch(filtersRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectFilterValue(state: AppState): boolean {
  return state.filter.isFilterOpened;
}

export function selectFilterWasAppliedValue(state: AppState): boolean {
  return state.filter.isFilterWasApplied;
}

export function selectFilterByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<BaseFilterType>> | null {
  return state.filter.filters[id];
}

export default reducer;
