import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { BaseOfPageNullable, ProductType } from '@/typings/model';
import { getBrandByAlias, getBrandProductsById } from '@/services/requests';

const brandsLoader = createResourceLoader<BaseOfPageNullable>(null);
const brandProductListLoader = createResourceLoader<Array<ProductType>>([]);

type StateType = {
  brands: Record<string, ResourceType<BaseOfPageNullable>>;
  brandProductList: Record<number, ResourceType<Array<ProductType>>>;
};

const initialState: StateType = {
  brands: {},
  brandProductList: [],
};

const brandSlice = createSlice({
  name: 'brand',
  initialState: initialState,
  reducers: {
    /** Brands */
    brandsRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.brands[action.payload.key] = brandsLoader.pending();
    },
    brandsRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, BaseOfPageNullable>>
    ) {
      state.brands[action.payload.key] = brandsLoader.fulfill(
        action.payload.value
      );
    },
    brandsRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.brands[action.payload.key] = brandsLoader.reject();
    },

    /** Brand Products */
    brandProductListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.brandProductList[
        action.payload.key
      ] = brandProductListLoader.pending();
    },
    brandProductListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.brandProductList[
        action.payload.key
      ] = brandProductListLoader.fulfill(action.payload.value);
    },
    brandProductListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.brandProductList[
        action.payload.key
      ] = brandProductListLoader.reject();
    },
  },
});

const { actions, reducer } = brandSlice;
export const {
  brandsRequestPending,
  brandsRequestFulfilled,
  brandsRequestRejected,
  brandProductListRequestPending,
  brandProductListRequestFulfilled,
  brandProductListRequestRejected,
} = actions;

export function getBrandByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<BaseOfPageNullable>> {
  return async (dispatch, getState) => {
    try {
      const collection = selectBrandByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && collection) {
        return collection.data;
      }
      dispatch(brandsRequestPending({ key: alias }));
      const response = await getBrandByAlias(alias);
      dispatch(
        brandsRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(brandsRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getBrandProductsByIdThunk(
  id: number,
  searchParams: URLSearchParams,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectBrandProductsByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(brandProductListRequestPending({ key: id }));
      const response = await getBrandProductsById(id, searchParams);

      dispatch(
        brandProductListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(brandProductListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectBrandByAliasResource(
  state: AppState,
  alias: string
): ResourceType<BaseOfPageNullable> {
  return state.pages.brand.brands[alias];
}

export function selectBrandProductsByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<ProductType>> {
  return state.pages.brand.brandProductList[id];
}

export default reducer;
