import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, MapEntry, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { BaseOfPageNullable, ProductType } from '@/typings/model';
import { getOfferByAlias, getOfferProductsById } from '@/services/requests';

const offersLoader = createResourceLoader<BaseOfPageNullable>(null);
const offerProductListLoader = createResourceLoader<Array<ProductType>>([]);

type StateType = {
  offers: Record<string, ResourceType<BaseOfPageNullable>>;
  offerProductList: Record<number, ResourceType<Array<ProductType>>>;
};

const initialState: StateType = {
  offers: {},
  offerProductList: [],
};

const offerSlice = createSlice({
  name: 'offer',
  initialState: initialState,
  reducers: {
    /** Offers */
    offersRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.offers[action.payload.key] = offersLoader.pending();
    },
    offersRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, BaseOfPageNullable>>
    ) {
      state.offers[action.payload.key] = offersLoader.fulfill(
        action.payload.value
      );
    },
    offersRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.offers[action.payload.key] = offersLoader.reject();
    },

    /** Offer Products */
    offerProductListRequestPending(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.pending();
    },
    offerProductListRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<ProductType>>>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.fulfill(action.payload.value);
    },
    offerProductListRequestRejected(
      state,
      action: PayloadAction<{ key: number }>
    ) {
      state.offerProductList[
        action.payload.key
      ] = offerProductListLoader.reject();
    },
  },
});

const { actions, reducer } = offerSlice;
export const {
  offersRequestPending,
  offersRequestFulfilled,
  offersRequestRejected,
  offerProductListRequestPending,
  offerProductListRequestFulfilled,
  offerProductListRequestRejected,
} = actions;

export function getOfferByAliasThunk(
  alias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<BaseOfPageNullable>> {
  return async (dispatch, getState) => {
    try {
      const collection = selectOfferByAliasResource(getState(), alias);

      if (!options?.shouldInvalidate && collection) {
        return collection.data;
      }
      dispatch(offersRequestPending({ key: alias }));
      const response = await getOfferByAlias(alias);
      dispatch(
        offersRequestFulfilled({
          key: alias,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(offersRequestRejected({ key: alias }));
      return null;
    }
  };
}

export function getOfferProductsByIdThunk(
  id: number,
  searchParams: URLSearchParams,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<ProductType>>> {
  return async (dispatch, getState) => {
    try {
      const children = selectOfferProductsByIdResource(getState(), id);

      if (!options?.shouldInvalidate && children) {
        return children.data;
      }

      dispatch(offerProductListRequestPending({ key: id }));
      const response = await getOfferProductsById(id, searchParams);

      dispatch(
        offerProductListRequestFulfilled({
          key: id,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(offerProductListRequestRejected({ key: id }));
      return [];
    }
  };
}

export function selectOfferByAliasResource(
  state: AppState,
  alias: string
): ResourceType<BaseOfPageNullable> {
  return state.pages.offer.offers[alias];
}

export function selectOfferProductsByIdResource(
  state: AppState,
  id: number
): ResourceType<Array<ProductType>> {
  return state.pages.offer.offerProductList[id];
}

export default reducer;
